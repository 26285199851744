<template>
  <v-table fixed-header style="height: 100%">
    <thead>
      <tr>
        <th>
          <v-card style="width: 100%;">
            <v-card-title>
              <h3>Evalueringsteknisk pris</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span class="total-price">{{ $format.currency2(price.price) }}</span></v-col>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-dialog style="width: 1400px;" scrollable>
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          class="secondary-button-mini"
                        >
                          Se beregning
                        </v-btn>
                      </template>
                      <PriceDetails
                        :document="price.document"
                      />
                    </v-dialog>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div style="width: 100%">
            <v-stepper editable>
              <v-stepper-header>
                <template v-for="(item, id) in stepperItems" :key="id">
                  <v-stepper-item
                    :title="item.title"
                    :value="id + 1"
                    :complete="item.complete"
                  >
                  </v-stepper-item>
                  <v-divider
                    v-if="id !== stepperItems.length - 1"
                    :key="id"
                  ></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-window>
                <v-stepper-window-item :value="1">
                  <v-card class="canvas pa-3">
                    <v-card-title>
                      <h3>Kontraktlængde
                        <DimsTooltip>
                          Kontraktens længde i år (uden optioner)
                        </DimsTooltip>
                      </h3>
                    </v-card-title>
                    <v-card-text>
                      <NumericInput
                        v-model="priceParameterSet.contractLength"
                        :min-value="1"
                        :max-value="6"
                        required
                        variant="outlined"
                      >
                      </NumericInput>
                    </v-card-text>
                  </v-card>
                </v-stepper-window-item>
                <v-stepper-window-item :value="2">
                  <v-row>
                    <v-col>
                      <v-card color="canvas pa-3" flat>
                        <v-card>
                          <v-card-title>
                            <h3>Integrationer til offentlige komponeter</h3>
                          </v-card-title>
                          <v-card-text>
                            <div v-if="(publicIntegrations?.length ?? 0) > 0">
                              <div v-for="(publicIntegration, id) in publicIntegrations" :key="id">
                                <v-checkbox :value="publicIntegration.id" v-model="priceParameterSet.publicIntegrationIds" density="comfortable" hide-details>
                                  <template #label>
                                    <div class="d-flex">
                                      {{ publicIntegration.integrationPublicComponent_0219?.integrationPublicComponentName_0219 }}
                                    </div>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>KOMBIT integrationer</h3>
                          </v-card-title>
                          <v-card-text>
                            <div v-if="(kombitIntegrations?.length ?? 0) > 0">
                              <div v-for="(integration, id) in kombitIntegrations" :key="id">
                                <v-checkbox :value="integration.id" v-model="priceParameterSet.kombitIntegrationIds" density="comfortable" hide-details>
                                  <template #label>
                                    <div class="d-flex">
                                      {{ integration.name }}
                                      <DimsTooltip>
                                        {{ integration.description }}
                                      </DimsTooltip>
                                    </div>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-window-item>
                <v-stepper-window-item :value="3">
                  <v-row>
                    <v-col>
                      <v-card color="canvas pa-3" flat>
                        <v-card>
                          <v-card-title>
                            <h3>Optioner</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-checkbox v-model="priceParameterSet.option1" density="comfortable" hide-details>
                              <template #label>
                                <div class="d-flex">
                                  Option 1
                                  <DimsTooltip>
                                    Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for exitproces.
                                  </DimsTooltip>
                                </div>
                              </template>
                            </v-checkbox>
                            <v-checkbox v-model="priceParameterSet.option2" density="comfortable" hide-details>
                              <template #label>
                                <div class="d-flex">
                                  Option 2
                                  <DimsTooltip>
                                    Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for opbevaring af data efter kontraktens ophør.
                                  </DimsTooltip>
                                </div>
                              </template>
                            </v-checkbox>
                            <v-checkbox v-model="priceParameterSet.option3" density="comfortable" hide-details>
                              <template #label>
                                <div class="d-flex">
                                  Option 3
                                  <DimsTooltip>
                                    Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for arkivering.
                                  </DimsTooltip>
                                </div>
                                <NumericInput
                                  v-if="priceParameterSet.option3"
                                  v-model="priceParameterSet.option3Count"
                                  variant="outlined"
                                  hide-details
                                  required
                                  prefix="Antal arkiveringer:"
                                  class="ml-2"
                                />
                              </template>
                            </v-checkbox>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>Konsulenttimer
                              <DimsTooltip>
                                Forventet timeforbrug
                              </DimsTooltip>
                            </h3>
                          </v-card-title>
                          <v-card-text>
                            <v-table>
                              <tr>
                                <td>
                                  Juniorkonsulenter
                                </td>
                                <td>
                                  <NumericInput
                                    v-model="priceParameterSet.juniorConsultHours"
                                    variant="outlined"
                                    hide-details
                                    required
                                    class="ml-2"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Konsulenter
                                </td>
                                <td>
                                  <NumericInput
                                    v-model="priceParameterSet.standardConsultHours"
                                    variant="outlined"
                                    hide-details
                                    required
                                    class="ml-2"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Seniorkonsulenter/specialister
                                </td>
                                <td>
                                  <NumericInput
                                    v-model="priceParameterSet.seniorConsultHours"
                                    variant="outlined"
                                    hide-details
                                    required
                                    class="ml-2"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  Chefkonsulenter/partnere
                                </td>
                                <td>
                                  <NumericInput
                                    v-model="priceParameterSet.chiefConsultHours"
                                    variant="outlined"
                                    hide-details
                                    required
                                    class="ml-2"
                                  />
                                </td>
                              </tr>
                            </v-table>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>FLIS</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-radio-group
                              v-if="(flisOptions?.length ?? 0) > 0"
                              v-model="priceParameterSet.commonManagementInformationProductId"
                            >
                              <div v-for="(flis, id) in flisOptions" :key="id">
                                <v-radio :value="flis.id">
                                  <template #label>
                                    {{ flis.managementInfoFrequency_0219 }}
                                  </template>
                                </v-radio>
                              </div>
                              <v-radio :value="null">
                                <template #label>
                                  Der skal ikke indgå ledelsesinformation
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>LIS</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-radio-group
                              v-if="(lisOptions?.length ?? 0) > 0"
                              v-model="priceParameterSet.managementInformationProductId"
                            >
                              <div v-for="(lis, id) in lisOptions" :key="id">
                                <v-radio :value="lis.id">
                                  <template #label>
                                    {{ lis.managementInfoFrequency_0219 }}
                                  </template>
                                </v-radio>
                              </div>
                              <v-radio :value="null">
                                <template #label>
                                  Der skal ikke indgå ledelsesinformation
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-window-item>
                <v-stepper-window-item :value="4">
                  <v-row>
                    <v-col>
                      <v-card color="canvas pa-3" flat>
                        <v-card>
                          <v-card-title>
                            <h3>Funktionelle tillægsydelser</h3>
                          </v-card-title>
                          <v-card-text>
                            <div v-if="(addOns?.length ?? 0) > 0">
                              <div v-for="(addOn, id) in addOns" :key="id">
                                <v-checkbox :value="addOn.id" v-model="priceParameterSet.functionalAddOnIds" density="comfortable" hide-details>
                                  <template #label>
                                    <div class="d-flex">
                                      {{ addOn.functionalAddOnId_0219 }} - {{ addOn.name }}
                                      <DimsTooltip>
                                        {{ addOn.description }}
                                      </DimsTooltip>
                                    </div>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>Leverandørspecifikke integrationer</h3>
                          </v-card-title>
                          <v-card-text>
                            <div v-if="(supplierIntegrations?.length ?? 0) > 0">
                              <div v-for="(integration, id) in supplierIntegrations" :key="id">
                                <v-checkbox :value="integration.id" v-model="priceParameterSet.supplierSpecificIntegrationIds" density="comfortable" hide-details>
                                  <template #label>
                                    <div class="d-flex">
                                      {{ integration.name }}
                                      <DimsTooltip>
                                        {{ integration.description }}
                                      </DimsTooltip>
                                    </div>
                                  </template>
                                </v-checkbox>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-window-item>
                <v-stepper-window-item :value="5">
                  <v-row>
                    <v-col>
                      <v-card color="canvas pa-3" flat>
                        <v-card>
                          <v-card-title>
                            <h3>Implementeringstype</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-radio-group v-model="priceParameterSet.implementationType">
                              <v-radio value="ExistingImplementation">
                                <template #label>
                                  <div class="d-flex">
                                    Type 1: Servicen er allerede i brug i min organisation
                                    <DimsTooltip>
                                      Vælg denne mulighed hvis den service leverandøren udbyder allerede er i brug i din organisation og implementeringen
                                      er en opdatering eller udvidelse af noget eksisterende.
                                    </DimsTooltip>
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio value="NewImplementation">
                                <template #label>
                                  <div class="d-flex">
                                    Type 2: Servicen er ikke før brugt i min organisation
                                    <DimsTooltip>
                                      Vælg denne mulighed hvis den service leverandøren udbyder er ny i din organisation og implementeringen er en installation helt fra grunden.
                                    </DimsTooltip>
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>Uddannelsespakker</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-data-table
                              v-model="educationCounts"
                              :items="educationCounts"
                              :headers="educationHeaders"
                              dense
                              hide-default-footer
                              items-per-page="-1"
                            >
                              <template
                                #item.trainTheTrainer="{ item }"
                              >
                                <NumericInput class="py-1" density="compact" variant="outlined" required v-model="item.trainTheTrainer" hide-details />
                              </template>
                              <template
                                #item.superuserEducation="{ item }"
                              >
                                <NumericInput class="py-1" density="compact" variant="outlined" required v-model="item.superuserEducation" hide-details />
                              </template>
                              <template
                                #item.enduserEducation="{ item }"
                              >
                                <NumericInput class="py-1" density="compact" variant="outlined" required v-model="item.enduserEducation" hide-details />
                              </template>
                              <template
                                #item.enduserEducationRefresh="{ item }"
                              >
                                <NumericInput class="py-1" density="compact" variant="outlined" required v-model="item.enduserEducationRefresh" hide-details />
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                        <v-card>
                          <v-card-title>
                            <h3>Beregningsenheder</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-data-table
                              v-model="calculationUnits"
                              :items="calculationUnits"
                              :headers="headers"
                              dense
                              hide-default-footer
                              items-per-page="-1"
                            >
                              <template
                                #item.count="{ item }"
                              >
                                <NumericInput class="py-1" density="compact" variant="outlined" required v-model="item.count" hide-details />
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-stepper-window-item>
              </v-stepper-window>
            </v-stepper>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
  <LoadingSpinner :loadingMessage="loadingMessage" :visible="isLoading" />
</template>

<script setup lang="ts">
import { CalculationUnitValue, NumericInput, PriceResult, useBackend } from '@dims/components';
import { computed, ref, watch } from 'vue';
import { forEach } from 'lodash';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import { Product0219 } from '@/models/PIM/Product0219';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { PriceParameterSet } from '@/models/PriceParameter';
import PriceDetails from './PriceDetails.vue';
import { ManagementInformation0219 } from '@/models/PIM/ManagementInformation0219';
import { EducationCount } from '@/models/EducationCount';
import { KombitIntegration0219 } from '@/models/PIM/KombitIntegration0219';
import { PublicIntegration0219 } from '@/models/PIM/PublicIntegration0219';

interface SupplierReferences_0219 {
  readonly service_0219: Product0219[];
  readonly functionalAddOn_0219: FunctionalAddOn0219[];
  readonly integrationPublicComponent_0219: PublicIntegration0219[];
  readonly kombitIntegration_0219: KombitIntegration0219[];
  readonly managementInfo_0219: ManagementInformation0219[];
  readonly supplierSpecificIntegration_0219: SupplierSpecificIntegration0219[];
}
const { product,
  variant,
  priceParameterSet,
  references,
  allEducationCounts,
} = defineProps<{ product: Product0219,
  variant: Variant0219,
  priceParameterSet: PriceParameterSet,
  references: SupplierReferences_0219,
  allEducationCounts: EducationCount[],
 }>();
const headers = [
  { title: 'Enhed', value: 'name', width: '70px' },
  { title: 'Beskrivelse', value: 'description', width: '40%' },
  { title: 'Antal', value: 'count', width: '20%' },
];
const educationHeaders = [
  { title: 'Område', value: 'area', width: '25%' },
  { title: 'Train the trainer', value: 'trainTheTrainer', width: '15%' },
  { title: 'Superbrugeruddannelse', value: 'superuserEducation', width: '15%' },
  { title: 'Slutbrugeruddannelse', value: 'enduserEducation', width: '15%' },
  { title: 'Slutbrugeruddannelse (refresh)', value: 'enduserEducationRefresh', width: '15%' },
];
const stepperItems = [
  { title: 'Behov', complete: false },
  { title: 'Specifikationer', complete: false },
  { title: 'Tilkøb', complete: false },
  { title: 'Services', complete: false },
  { title: 'Evaluering', complete: false },
];
const backend = useBackend();
const loadingMessage = ref('Henter dine services');
const isLoading = ref(false);
const educationCounts = computed(() => relevantEducationCounts());
const calculationUnits = computed(() => relevantCalculationUnits());
const price = ref<PriceResult>({});
const debounceCalc = ref(0);
const lisOptions = computed(() => references.managementInfo_0219.filter((x) => x.managementInfoCategory_0219 === 'LIS'
&& x.serviceLevel_0219 === variant.serviceLevel_0219));
const flisOptions = computed(() => references.managementInfo_0219.filter((x) => x.managementInfoCategory_0219 === 'FLIS'
&& x.serviceLevel_0219 === variant.serviceLevel_0219));
const addOns = computed(() => references.functionalAddOn_0219.filter((x) => x.serviceLevel_0219 === variant.serviceLevel_0219));
const supplierIntegrations = computed(() => references.supplierSpecificIntegration_0219.filter((x) => x.serviceLevel_0219 === variant.serviceLevel_0219));
const publicIntegrations = computed(() => references.integrationPublicComponent_0219.filter((x) => x.serviceLevel_0219 === variant.serviceLevel_0219));
const kombitIntegrations = computed(() => references.kombitIntegration_0219.filter((x) => x.serviceLevel_0219 === variant.serviceLevel_0219));

watch(
  () => variant.id,
  () => { if (priceParameterSet.contractLength) calculatePrice(priceParameterSet); else price.value = {}; },
);

watch(
  () => priceParameterSet,
  (p) => {
    if (p.contractLength && p.productId && p.variantId) {
      if (debounceCalc.value) {
        window.clearTimeout(debounceCalc.value);
      }
      debounceCalc.value = window.setTimeout(() => {
        calculatePrice(p);
      }, 2000);
    }
  },
  { deep: true },
);

function calculatePrice(p: PriceParameterSet) {
  loadingMessage.value = 'Beregner pris';
  isLoading.value = true;
  const parameters = { ...p };
  parameters.contractLength = (p.contractLength ?? 0) + 2;
  void backend.directOfferService.getSimplePrice(parameters)
    .then((result) => { price.value = result; isLoading.value = false; });
}

function relevantEducationCounts() {
  const ecs: EducationCount[] = [];
  const service = allEducationCounts.find((x) => x.refId === variant.id);
  if (service) ecs.push(service);
  const selectedAddOns = references.functionalAddOn_0219
    .filter((x) => priceParameterSet.functionalAddOnIds?.includes(x.id));
  forEach(selectedAddOns, (a) => {
    const addOn = allEducationCounts.find((x) => x.refId === a.id);
    if (addOn) ecs.push(addOn);
  });
  priceParameterSet.educationCounts = ecs;
  return ecs;
}

function relevantCalculationUnits() {
  const cus: CalculationUnitValue[] = [];
  const productUnit = product.calculationUnitValue;
  if (productUnit) cus.push(productUnit);
  const addonUnits = references.functionalAddOn_0219
    .filter((x) => priceParameterSet.functionalAddOnIds?.includes(x.id) && !cus.map((c) => c.id).includes(x.calculationUnitValue?.id))
    .map((x) => x.calculationUnitValue)
    .filter((x) => x !== undefined);
  forEach(addonUnits, (a) => { if (!cus.map((c) => c.id).includes(a.id)) cus.push(a); });
  const lisUnit = references.managementInfo_0219
    .find((x) => x.id === priceParameterSet.managementInformationProductId)
    ?.calculationUnitValue;
  if (lisUnit && !cus.map((c) => c.id).includes(lisUnit.id)) cus.push(lisUnit);
  const flisUnit = references.managementInfo_0219
    .find((x) => x.id === priceParameterSet.commonManagementInformationProductId)
    ?.calculationUnitValue;
  if (flisUnit && !cus.map((c) => c.id).includes(flisUnit.id)) cus.push(flisUnit);
  const integrationUnits = references.supplierSpecificIntegration_0219
    .filter((x) => priceParameterSet.supplierSpecificIntegrationIds?.includes(x.id) && !cus.map((c) => c.id).includes(x.calculationUnitValue?.id))
    .map((x) => x.calculationUnitValue)
    .filter((x) => x !== undefined);
  forEach(integrationUnits, (a) => { if (!cus.map((c) => c.id).includes(a.id)) cus.push(a); });
  priceParameterSet.calculationUnitValues = cus;
  return cus;
}

</script>

<style scoped>

.small-table {
  width: 50%;
}

.total-price {
  font-size: x-large!important;
  font-weight: bolder!important;
  color: green;
}

.sub-header {
  font-size: small;
  font-style: italic;
}
</style>
